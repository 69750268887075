import React, { useState, useEffect, useContext } from "react";
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import { Droppable, Draggable } from "react-beautiful-dnd";
import Project from "../Projects/Project.js";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert'; 
import { Modal } from 'react-responsive-modal';
import NewGroup from "./NewGroup.js";
import {ProjectContext} from "../Projects/ProjectContext.js";

export default function Group({ group, refetchData }) {
  const {setGroups} = useContext(ProjectContext);
  const match = useRouteMatch();
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(() => {
    const storedState = localStorage.getItem(`group_${group.token}_isOpen`);
    return storedState === null ? true : JSON.parse(storedState);
  });

  useEffect(() => {
    localStorage.setItem(`group_${group.token}_isOpen`, JSON.stringify(isOpen));
  }, [isOpen, group.token]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleDelete = () => {
    confirmAlert({
      title: 'Confirm Delete',
      message: 'This will delete the group and all projects will be taken out of the group.',
      buttons: [
        {
          label: 'No',
          className: 'btn background-3 btn-small',
          onClick: () => {} // Do nothing if "No" is clicked
        },
        {
          label: 'Yes',
          className: 'btn btn-danger btn-small',
          onClick: () => {
            axios.delete(`/api/o/${match.params.organization_id}/groups/${group.token}`)
            .then(function(response){
              // console.log(response);
              if(response.data.success){
                setGroups(prevState => _.reject(prevState, { token: group.token }));
                refetchData();
              } else {
                response.data.errors.forEach((error) => {
                  notice(error);
                });
              }
            })
            .catch(function(error){
              console.log(error)
              notice("An error occured");
              reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
            })
            .then(function () {

            });
          }
        },
      ]
    });
  }

  return (
    <div className="sidenav-group-wrapper">
      <Droppable droppableId={group.token}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={`hover-parent-opacity cursor-default ${snapshot.isDraggingOver ? 'dragging-over group-projects' : ''}`}
          >
            <div className="display-flex justify-content-between align-items-center">
                <div className="daas-platform-links-a sidebar-text-color truncate display-flex justify-content-between align-items-center" onClick={toggleOpen}>
                  <div className="display-flex align-items-center" style={{width: "-webkit-fill-available"}}>
                    {group.emoji}
                    <span className="ml-10 truncate-inside">{group.title}</span>
                    <i className={`fas font-12 ml-10 background-hover color-1 ${isOpen ? 'fa-caret-down' : 'fa-caret-right'}`}></i>
                  </div> 
                </div>

                <div class="btn-group">
                  <a type="button" class="btn sidenav-navigation-font-icon color-1 background-hover dropdown-toggle hover-child-opacity" data-toggle="dropdown">
                    <i className="fal fa-ellipsis-v color-1"></i>
                  </a>
                  <ul class="dropdown-menu pull-right animated fadeInUp min-width-unset" role="menu">
                  <li><a onClick={() => setOpen(true)}><i className="fal fa-pencil"></i>Edit</a></li>
                    <li><a onClick={handleDelete}><i className="fal fa-trash-alt"></i>Delete</a></li>

                    <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
                      <h4 className="color-black-always modal-title">Edit Group</h4>
                      <NewGroup group={group} refetchData={refetchData} onCloseModal={() => setOpen(false)}/>
                    </Modal>
                  </ul>
                </div>
            </div>

            {isOpen && (
              <div className="group-projects-list">
                {group.projects.map((project, index) => (
                  <Draggable key={project.token} draggableId={`project-${project.token}`} index={index}>
                    {(provided) => (
                      <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Project key={project.token} fetchProjects={fetchProjects} project={project} privateProject={false} index={index}/>
                      </li>
                    )}
                  </Draggable>
                ))}

                {group.projects.length === 0 && 
                  <div className="text-center opacity-4 font-10 display-flex justify-content-center align-items-center" style={{paddingTop: "5px"}}>
                    <p>Drag a project to the group</p>
                  </div>
                }
              </div>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}